import React from 'react';

export const backend = () => (
  <svg id="Layer_5" enableBackground="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m48 36.816v4.184h2v-4.184c1.161-.414 2-1.514 2-2.816s-.839-2.402-2-2.816v-4.196c-.348.008-.685.012-1 .012s-.652-.004-1-.011v4.196c-.847.302-1.514.969-1.816 1.816h-19.184v2h19.184c.302.846.969 1.513 1.816 1.815z" />
      <path d="m15 51.184v-2.184h-2v2.184c-1.161.414-2 1.514-2 2.816 0 1.654 1.346 3 3 3 1.302 0 2.402-.839 2.816-2h19.184v-2h-19.184c-.302-.847-.969-1.514-1.816-1.816z" />
      <path d="m35 11.508v-.508h-8v2h8z" />
      <path d="m37 19.271v3.661c.515.759 4.86 2.068 12 2.068s11.485-1.309 12-2.068v-3.661c-3.607 1.666-10.927 1.729-12 1.729s-8.393-.063-12-1.729z" />
      <ellipse cx="49" cy="5" rx="11.945" ry="2" />
      <path d="m49 9c-1.073 0-8.393-.063-12-1.729v3.652c.485.758 4.831 2.077 12 2.077s11.515-1.319 12-2.077v-3.652c-3.607 1.666-10.927 1.729-12 1.729z" />
      <path d="m37 13.271v3.652c.485.758 4.831 2.077 12 2.077s11.515-1.319 12-2.077v-3.652c-3.607 1.666-10.927 1.729-12 1.729s-8.393-.063-12-1.729z" />
      <path d="m25 4c0-.552-.448-1-1-1h-20c-.552 0-1 .448-1 1v5h22zm-18 3h-2v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z" />
      <path d="m4 21h20c.552 0 1-.448 1-1v-9h-22v9c0 .552.448 1 1 1zm11-2h-2v-2h2zm3-5h4v4h-4zm-13-1h10v2h-10zm0 4h6v2h-6z" />
      <path d="m25 46v-9h-22v9c0 .552.448 1 1 1h20c.552 0 1-.448 1-1zm-20-7h2v2h-2zm18 6h-18v-2h18zm0-4h-14v-2h14z" />
      <path d="m25 30c0-.552-.448-1-1-1h-20c-.552 0-1 .448-1 1v5h22zm-18 3h-2v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z" />
      <path d="m38 60c0 .552.448 1 1 1h20c.552 0 1-.448 1-1v-9h-22zm20-1h-2v-2h2zm-10-6h10v2h-10zm0 4h6v2h-6zm-5-3c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z" />
      <path d="m59 43h-20c-.552 0-1 .448-1 1v5h22v-5c0-.552-.448-1-1-1zm-17 4h-2v-2h2zm4 0h-2v-2h2zm4 0h-2v-2h2z" />
      <path d="m13 23h2v4h-2z" />
    </g>
  </svg>
);
export default backend;
