import planningImage from '../../../assets/images/planning.jpg';
import deploying from '../../../assets/images/wireframing.jpg';
import architecting from '../../../assets/images/architicting.jpg';
import coding from '../../../assets/images/coding.jpg';
import testing from '../../../assets/images/testing.jpg';

export const headerData = {
  title: 'Building webApps',
  description: 'I can help you building your app from planning to shipping to the end customers',
};

const columns = [
  {
    id: 1,
    name: 'project planning',
    title: 'Planning',
    description: 'Analyzing. sketching and building an app road map',
    clickable: true,
    active: false,
    image: planningImage,
  },
  {
    id: 2,
    name: 'software Architecture',
    title: 'Architecting',
    description: 'Setting app main sectors and areas, services, UI',
    clickable: true,
    active: false,
    image: architecting,
  },
  {
    id: 3,
    name: 'coding',
    title: 'Coding',
    description: 'Testable, maintainable and scalable code',
    clickable: true,
    active: true,
    image: coding,
  },
  {
    id: 4,
    name: 'test',
    title: 'Test',
    description: 'Functional, integration, Automated tests',
    clickable: true,
    active: false,
    image: testing,
  },
  {
    id: 5,
    name: 'deploy apps',
    title: 'Deploying',
    description: 'Continues integration and delivery to customers',
    clickable: true,
    active: false,
    image: deploying,

  },
];
export default columns;
