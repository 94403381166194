import { linkedin, github, twitter } from '../../assets/icons/svgIconsMap';

const footerData = {
  title: 'Find me on these platforms',
  copyRights: 'Made with love by Ali Ghali 2020',
  socialLinks: [
    { name: 'LinkedIn', url: 'https://www.linkedin.com/in/alighali-profile/', icon: linkedin },
    { name: 'GitHub', url: 'https://www.github.com/alighali85', icon: github },
    { name: 'Twitter', url: 'https://twitter.com/Ali_H_Ghali', icon: twitter },
  ],
};
export default footerData;
