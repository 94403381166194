import React from 'react';

export const frontend = () => (
  <svg id="Layer_5" enableBackground="new 0 0 64 64" height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m13 5h2v2h-2z" />
      <path d="m21 5h2v2h-2z" />
      <path d="m17 5h2v2h-2z" />
      <path d="m58 13h-3v-9c0-1.654-1.346-3-3-3h-40c-1.654 0-3 1.346-3 3v9h-3c-2.757 0-5 2.243-5 5v30c0 2.757 2.243 5 5 5h15v4h-2c-1.654 0-3 1.346-3 3v3h32v-3c0-1.654-1.346-3-3-3h-2v-4h15c2.757 0 5-2.243 5-5v-30c0-2.757-2.243-5-5-5zm-3 6h2v24h-2zm-44-15c0-.552.449-1 1-1h40c.551 0 1 .448 1 1v5h-42zm0 7h42v32h-42zm29 34v1c0 .552-.449 1-1 1h-3v-2zm-6 0v2h-4v-2zm-6 0v2h-3c-.551 0-1-.448-1-1v-1zm17 14c.551 0 1 .448 1 1v1h-28v-1c0-.552.449-1 1-1zm-22-2v-4h18v4zm38-9c0 1.654-1.346 3-3 3h-52c-1.654 0-3-1.346-3-3v-30c0-1.654 1.346-3 3-3h3v2h-2c-1.103 0-2 .897-2 2v24c0 1.103.897 2 2 2h15v1c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-1h15c1.103 0 2-.897 2-2v-24c0-1.103-.897-2-2-2h-2v-2h3c1.654 0 3 1.346 3 3zm-52-29v24h-2v-24z" />
      <path d="m45.219 29.625 3.5 4.375-3.5 4.375 1.562 1.25 4.5-5.625-4.5-5.625z" />
      <path d="m37.219 28.375-4.5 5.625 4.5 5.625 1.562-1.25-3.5-4.375 3.5-4.375z" />
      <path d="m34.929 33h14.142v2h-14.142z" transform="matrix(.142 -.99 .99 .142 2.394 70.761)" />
      <path d="m15 20c0 1.654 1.346 3 3 3h1v-2h-1c-.551 0-1-.448-1-1 0-.771-.301-1.468-.78-2 .48-.532.78-1.229.78-2 0-.552.449-1 1-1h1v-2h-1c-1.654 0-3 1.346-3 3 0 .552-.449 1-1 1h-1v2h1c.551 0 1 .448 1 1z" />
      <path d="m29 32c0-1.654-1.346-3-3-3h-1v2h1c.551 0 1 .448 1 1 0 .771.301 1.468.78 2-.48.532-.78 1.229-.78 2 0 .552-.449 1-1 1h-1v2h1c1.654 0 3-1.346 3-3 0-.552.449-1 1-1h1v-2h-1c-.551 0-1-.448-1-1z" />
      <path d="m13 37h10v2h-10z" />
      <path d="m13 33h10v2h-10z" />
      <path d="m13 29h10v2h-10z" />
      <path d="m13 25h10v2h-10z" />
      <path d="m21 17h10v2h-10z" />
      <path d="m21 21h10v2h-10z" />
      <path d="m21 13h10v2h-10z" />
      <path d="m35 25h14c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-14c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2zm0-10h14l.001 8h-14.001z" />
    </g>
  </svg>
);

export default frontend;
