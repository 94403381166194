import {
  frontend, testing, teamwork, devops, backend, tools,
} from '../../../assets/icons/svgIconsMap';

import js from '../../../assets/icons/png/javascript.png';
import react from '../../../assets/icons/png/react.png';
import angular from '../../../assets/icons/png/angular.png';
import jquery from '../../../assets/icons/png/jquery.png';
import redux from '../../../assets/icons/png/redux.png';

import jest from '../../../assets/icons/png/jest.png';
import testinglibrary from '../../../assets/icons/png/testinglibrary.png';
import puppeteer from '../../../assets/icons/png/puppeteer.png';
import protractor from '../../../assets/icons/png/protractor.png';

import firebase from '../../../assets/icons/png/firebase.png';
import mongodb from '../../../assets/icons/png/mongodb.png';
import postgresql from '../../../assets/icons/png/postgresql.png';

import cicd from '../../../assets/icons/png/cicd.png';
import docker from '../../../assets/icons/png/docker.png';
import aws from '../../../assets/icons/png/aws.png';

import git from '../../../assets/icons/png/git.png';
import vscode from '../../../assets/icons/png/vscode.png';
import debug from '../../../assets/icons/png/bug.png';
import api from '../../../assets/icons/png/api.png';

import agile from '../../../assets/icons/png/agile.png';
import atlassian from '../../../assets/icons/png/atlassian.png';
import sketch from '../../../assets/icons/png/sketch.png';
import collaborating from '../../../assets/icons/png/collaborating.png';
import corssFunction from '../../../assets/icons/png/crossfunction.png';


export const headerData = {
  title: 'Skills and TechStack',
  description: 'years of experience skills from designing to delivering apps, with focusing on front-end',
};

const contentData = [
  {
    title: 'Front End',
    icon: frontend,
    listItems: [
      {
        title: 'JavaScript',
        subTitle: 'ES6-ES7/ TypeScript',
        icon: js,
      },
      {
        title: 'React',
        subTitle: 'hooks/ Best Practices',
        icon: react,
      },
      {
        title: 'Angular',
        subTitle: 'V2-5',
        icon: angular,
      },
      {
        title: 'Legacy frameworks',
        subTitle: 'JQuery/Backbone',
        icon: jquery,
      },
      {
        title: 'Redux/ Saga',
        subTitle: '',
        icon: redux,
      },
    ],
  },
  {
    title: 'Testing',
    icon: testing,
    listItems: [
      {
        title: 'Jest',
        subTitle: 'for React apps',
        icon: jest,
      },
      {
        title: 'Testing Library',
        subTitle: 'functional/integrartion',
        icon: testinglibrary,
      },
      {
        title: 'Protractor',
        subTitle: 'Angular/E2E test',
        icon: protractor,
      },
      {
        title: 'Puppeteer',
        subTitle: 'React/E2E test',
        icon: puppeteer,
      },
    ],
  },
  {
    title: 'BE/ Databases',
    icon: backend,
    listItems: [
      {
        title: 'FireBase',
        subTitle: 'backend and hosting',
        icon: firebase,
      },
      {
        title: 'PosgreSQL',
        subTitle: 'Relational DB',
        icon: postgresql,
      },
      {
        title: 'MongoDB',
        subTitle: 'Non SQL DB',
        icon: mongodb,
      },

    ],
  },
  {
    title: 'DevOps/deploy',
    icon: devops,
    listItems: [
      {
        title: 'CI/CD',
        subTitle: 'Travis, Circle CI, Github actions, AWS code pipe',
        icon: cicd,
      },
      {
        title: 'Containers',
        subTitle: 'Docker',
        icon: docker,
      },
      {
        title: 'AWS Hosting',
        subTitle: 'S3, CloudFront, lambda',
        icon: aws,
      },
    ],
  },
  {
    title: 'Developer tools',
    icon: tools,
    listItems: [
      {
        title: 'Git',
        subTitle: 'veresioning control, github. bitBucket',
        icon: git,
      },
      {
        title: 'Browsers develpoer tools',
        subTitle: 'Analyis and debug',
        icon: debug,
      },
      {
        title: 'Network',
        subTitle: 'HTTPS, CORS, RESTful API',
        icon: api,
      },
      {
        title: 'Modern Editors',
        subTitle: 'code pairing/ remote working',
        icon: vscode,
      },
    ],
  },
  {
    title: 'Team work',
    icon: teamwork,
    listItems: [
      {
        title: 'JIRA/ Confluence',
        subTitle: 'Project managing',
        icon: atlassian,
      },
      {
        title: 'Agile',
        subTitle: 'scrums, sprint',
        icon: agile,
      },
      {
        title: 'Cross Functional',
        subTitle: 'FE, BE, DevOps',
        icon: corssFunction,
      },
      {
        title: 'Collaborating tools',
        subTitle: 'for UI, Zipplem, insomia, ',
        icon: collaborating,
      },
      {
        title: 'Design/ wireframming',
        subTitle: 'Adobe XD, Sketch, figma',
        icon: sketch,
      },
    ],
  },
];

export default contentData;
