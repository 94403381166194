import React from 'react';

export const devops = () => (
  <svg height="512" viewBox="0 0 64 64" width="512" xmlns="http://www.w3.org/2000/svg">
    <g id="operating_system" data-name="operating system">
      <path d="m56 18h-.21a10.792 10.792 0 0 0 1.21-5 11 11 0 0 0 -21.67-2.67 8.488 8.488 0 0 0 -14.33 6.17 3.507 3.507 0 0 0 .4 1.53 2.835 2.835 0 0 0 -.42.08c-1.75-1.44-5.46-2.11-8.98-2.11-4.82 0-10 1.25-10 4v18c0 2.75 5.18 4 10 4s10-1.25 10-4v-8h34a6 6 0 0 0 0-12zm-36 20c0 .51-2.75 2-8 2s-8-1.49-8-2v-3.48a18.866 18.866 0 0 0 8 1.48 18.866 18.866 0 0 0 8-1.48zm0-6c0 .51-2.75 2-8 2s-8-1.49-8-2v-3.48a18.866 18.866 0 0 0 8 1.48 18.866 18.866 0 0 0 8-1.48zm0-6c0 .51-2.75 2-8 2s-8-1.49-8-2v-3.48a18.866 18.866 0 0 0 8 1.48 18.866 18.866 0 0 0 8-1.48zm-8-4c-5.25 0-8-1.49-8-2s2.75-2 8-2 8 1.49 8 2-2.75 2-8 2zm44 6h-34v-8h1a1.014 1.014 0 0 0 .82-.42 1.035 1.035 0 0 0 .12-.92 6.909 6.909 0 0 0 -.47-.99 3.068 3.068 0 0 1 -.47-1.17 6.492 6.492 0 0 1 12.14-3.21 1 1 0 0 0 1.87-.48 9 9 0 1 1 16.18 5.59 1 1 0 0 0 .8 1.6h2.01a4 4 0 0 1 0 8z" />
      <path d="m62.64 56.36a.987.987 0 0 0 -.76-.36h-.88v-21a3.009 3.009 0 0 0 -3-3h-32a3.009 3.009 0 0 0 -3 3v21h-.88a1 1 0 0 0 -.98 1.17l.46 2.56a.359.359 0 0 0 .02.11 3.021 3.021 0 0 0 2.88 2.16h35a3.021 3.021 0 0 0 2.88-2.16.359.359 0 0 0 .02-.11l.46-2.56a.986.986 0 0 0 -.22-.81zm-37.64-21.36a1 1 0 0 1 1-1h32a1 1 0 0 1 1 1v21h-14a1 1 0 0 0 -1 1h-4a1 1 0 0 0 -1-1h-14zm35.45 24.32a1.014 1.014 0 0 1 -.95.68h-35a1.014 1.014 0 0 1 -.95-.68l-.23-1.32h14.68a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1h14.68z" />
      <path d="m11 44h2v2h-2z" />
      <path d="m13 48h-2v11a1 1 0 0 0 1 1h7v-2h-6z" />
      <path d="m46 45a4 4 0 1 0 -4 4 4 4 0 0 0 4-4zm-6 0a2 2 0 1 1 2 2 2 2 0 0 1 -2-2z" />
      <path d="m40 54h4a1 1 0 0 0 .97-.758l.167-.668.591.355a1 1 0 0 0 1.221-.151l2.829-2.828a1 1 0 0 0 .151-1.222l-.355-.591.668-.167a1 1 0 0 0 .758-.97v-4a1 1 0 0 0 -.758-.97l-.668-.167.355-.591a1 1 0 0 0 -.151-1.222l-2.829-2.828a1 1 0 0 0 -1.221-.151l-.591.355-.167-.668a1 1 0 0 0 -.97-.758h-4a1 1 0 0 0 -.97.758l-.167.668-.591-.355a1 1 0 0 0 -1.221.151l-2.829 2.828a1 1 0 0 0 -.151 1.222l.355.591-.668.167a1 1 0 0 0 -.758.97v4a1 1 0 0 0 .758.97l.668.167-.355.591a1 1 0 0 0 .151 1.222l2.829 2.828a1 1 0 0 0 1.221.151l.591-.355.167.668a1 1 0 0 0 .97.758zm-2.087-3.188-1.726-1.725.648-1.078a1 1 0 0 0 -.615-1.485l-1.22-.3v-2.443l1.22-.3a1 1 0 0 0 .615-1.485l-.648-1.078 1.726-1.725 1.078.647a1 1 0 0 0 1.485-.615l.3-1.22h2.438l.3 1.22a1 1 0 0 0 1.485.615l1.078-.647 1.725 1.725-.647 1.078a1 1 0 0 0 .615 1.485l1.22.3v2.438l-1.22.3a1 1 0 0 0 -.615 1.485l.647 1.078-1.725 1.725-1.078-.647a1.009 1.009 0 0 0 -.9-.067 1 1 0 0 0 -.587.682l-.3 1.22h-2.431l-.3-1.22a1 1 0 0 0 -1.485-.615z" />
      <path d="m53 44h4v2h-4z" />
      <path d="m27 44h4v2h-4z" />
      <path d="m29.5 14v-2a4.5 4.5 0 0 0 -4.47 3.976l1.987.232a2.5 2.5 0 0 1 2.483-2.208z" />
      <path d="m51 13h2a7.008 7.008 0 0 0 -7-7v2a5.006 5.006 0 0 1 5 5z" />
    </g>
  </svg>
);

export default devops;
