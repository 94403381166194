/**
 * item = {
    time: 'working time',
    title: 'Front-End developer',
    description: 'Worked on establishing new web platforms, and:',
    workList: [
      'item one',
      'item two'],
    company: {
      name: 'company name',
      link: 'https://mieterengel.de/',
    },
    location: '',
      references: {
      text: 'what people say',
      link: 'https://google.com',
    },
    jobDescription: ' shows above company description',
  },
 */

export const headerData = {
  title: 'Experiences',
  description: 'list of my recent jobs and programing related experiences',
};

const timelineItems = [
  {
    time: 'Oct 2018 - May 2020',
    title: 'Senior Front-End engineer',
    description: 'working within cross-functional team on:',
    company: {
      name: 'Architrave GmbH',
      link: 'https://www.architrave.de/',
    },
    location: 'Germany',
    workList: ['migrate old front-end development system to modern one', 'maintaining and build backbone app features', 'implement micro front end structure', 'build react micro apps'],
    references: false,
    jobDescription: 'See Architrave website:',
  }, {
    time: 'Oct 2019',
    title: 'Certified blockchain scholar',
    description: 'block chain scholar by Ethereum foundation, I learned:',
    company: {
      name: 'Ethereum',
      link: 'https://ethereum.org/foundation/',
    },
    location: 'Japan',
    workList: ['the basic of blockchain', 'decentralized apps', 'e-currency and mining', 'Ethereum foundation programs'],
    references: false,
    jobDescription: 'Selected as a Devcon V Scholar amongst 50 other candidates from around the world to participate, Earned a blockchain program certification',
  },
  {
    time: 'Oct 2017 - Oct 2018',
    title: 'Front-End developer',
    description: 'Worked on maintaining and creating new webapps, including:',
    workList: [
      'building react app from scratch',
      'building users platform using React/saga and NodeJS',
      'refactoring eCommerce wordpress webapp',
      'applying UI designs',
      'tracking and enhancing apps performance'],
    company: {
      name: 'MieterEngel',
      link: 'https://mieterengel.de/',
    },
    location: 'Germany',
    jobDescription: 'Miterengel is an online-organization that provides fast and reliable consultation for tenants who facing problems with their landlords, for more information see their website:',
  },
  {
    time: 'March 2017 - Sep 2017',
    title: 'Front-end developer (intern)',
    description: 'worked on Angular app project',
    company: {
      name: 'SAP',
      link: 'https://www.sap.com/products/real-estate-facilities-mgmt-cloud.html',
    },
    location: 'Germany',
    workList: ['testing Angular app E2E', 'fixing and tracking bugs', 'working with the designing team'],
    references: false,
    jobDescription: '',
  },
  {
    time: 'Jul 2016 - Mar 2017',
    title: 'web developer/member',
    description: 'volunteer within a group of developers, worked on:',
    company: {
      name: 'Coding amigos',
      link: 'https://wizardamigos.com/',
    },
    location: 'Germany',
    workList: ['Building web platform that helps new comers to find works',
      'learn and help others to learn programming'],
    references: false,
    jobDescription: 'the name was changes later to WizardAmigos',
  },
  {
    time: 'Dec 2014 - Aug 2015',
    title: 'Web administrator/ developer',
    description: 'maintain, test and the organization web sites',
    company: {
      name: 'CCSD',
      link: '',
    },
    location: 'Turkey',
    workList: ['building static websites', 'testing and maintain existing apps'],
    references: false,
    jobDescription: '',
  },
  {
    time: 'Sep 2009 - Mar 2012',
    title: 'Co founder/ developer',
    description: 'design, create and maintain websites:',
    company: {
      name: 'Compunet',
    },
    location: 'Syria',
    workList: [
      'help customers to shape their ideas',
      'designing interfaces',
      'develop and test apps'],
    references: false,
    jobDescription: `a start up company I established with some friends, 
    we have built tens of websites and platforms for agencies, 
    I was involved in the entire production circle from meeting the customers to deploying and tracking their websites`,
  },
];

export default timelineItems;
